import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import liff from '@line/liff';

const liff_id = { liffId: '2004697717-vKXO6DEN' };

liff.init(liff_id)
const ProtectedRouteLiff = () => {

   if (liff.getOS() == 'web') {
      window.location.href = "/";
      // return <Navigate to="/" replace={true} />
   }else{
      return <Outlet />;
   }
   //return <Outlet />;
}

export default ProtectedRouteLiff;