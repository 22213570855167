import { Link } from "react-router-dom";
import '../scss/tab_cart_status.scss';

const TabCartStatus = () => {
    let orderDetails = JSON.parse(localStorage.getItem('order_details'));
    let total = 0;
    if(orderDetails != null){
        orderDetails.foodList.forEach((item,key) => {
            total = total+parseFloat(item.foodPrice);
        });
        return(
            <Link to="/cart" id="tab-cart-status" className='p-4 shadow-md'>
                <div>
                    <span>ตระกร้า</span>
                </div>
                <div className='pe-4'>
                    <span>{total}</span>
                    <span className='ms-2'>บาท</span>
                </div>
            </Link>
        )
    }else{
        return(<div></div>)
    }
}
export default TabCartStatus