import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ProtectedRouteLiff from './ProtectedRouteLiff';
import FoodMenu from '../views/FoodMenu';
import PageCraeteOrder from '../views/PageCreateOrder';
import PageCart from '../views/PageCart';
const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <div>Login</div>,
            index: true
        },
        {
            //element: <ProtectedRoute isAuthenticated={isAuthenticated()} />,
            element:<ProtectedRouteLiff/>,
            children: [
                {
                    path: '/foodmenu',
                    element: <FoodMenu/>
                },
                {
                    path:'/order/:name/:price',
                    element:<PageCraeteOrder/>
                },
                {
                    path:'/cart',
                    element:<PageCart/>
                }
            ]
        },
        {
            path: '*',
            element: <div>404 Error - Nothing here...</div>
        }
    ]
);

export default router;