import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '../scss/topbar.scss';
import Logo from '../images/logo-tum-paidang.png';
const Topbar = () => {

    return (
        <nav id="topbar">
            <button type='button' id="btn_menu">
                {/* <FontAwesomeIcon icon={fas.faList} size='xl' /> */}
            </button>
            <div id='topbar-block-logo'>
                <img src={Logo} alt="Logo" />
            </div>
            <div id="block-profile">

            </div>
        </nav>
    )
}
export default Topbar;