import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/app.scss';
import '../src/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};
axios.defaults.timeout = 3000;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<App />
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

reportWebVitals();
