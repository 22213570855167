import React from 'react';
import router from './routes/Router';
import LiffLayouts from './Layouts/LiffLayouts';
import BlankLayouts from './Layouts/BlankLayouts';
function App() {
  const pathName = router.state.location.pathname;
  if(pathName == '/'){
    return <BlankLayouts/>;
  }else{
    return <LiffLayouts/>
  }

}

export default App;
