import '../scss/page_create_order.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import liff from '@line/liff';
import { useParams } from 'react-router';
import { useNavigate, Link } from "react-router-dom";
const liff_id = { liffId: '2004697717-vKXO6DEN' };
liff.init(liff_id)

const PageCraeteOrder = () => {
    const param = useParams();
    let orderDetails = JSON.parse(localStorage.getItem('order_details'));
   // const [foodQty, resFoodQty] = React.useState([]);
    React.useEffect(() => {

       if (orderDetails != null) {
            orderDetails.foodList.forEach((item,key) => {
                if(item.foodName == param.name){
                    document.getElementById('food_qty').value = item.foodQty;
                    document.getElementsByClassName('price')[0].innerText = item.foodPrice;
                }
            });
       }

    }, []);
   
    let navigate = useNavigate();
    const plus = () => {
        
        const food_qty = document.getElementById('food_qty');
        document.getElementById('food_qty').value = parseInt(food_qty.value) + 1;
        document.getElementsByClassName('price')[0].innerText = parseInt(document.getElementsByClassName('price')[0].innerText) + parseInt(param.price)
    }
    const minus = () => {
        const food_qty = document.getElementById('food_qty');
        
        if(food_qty.value > 1){
            document.getElementById('food_qty').value = parseInt(food_qty.value) - 1;
            document.getElementsByClassName('price')[0].innerText = parseInt(document.getElementsByClassName('price')[0].innerText) - parseInt(param.price)
        }
    }
    const orderAdd = async () => {

        const resLiffProfile = await liff.getProfile();

        if (orderDetails != null) {
            // orderDetails.foodList.push({
            //     foodName: param.name,
            //     foodPrice: param.price,
            // });
            orderDetails.foodList.forEach((item,key) => {
                if(item.foodName == param.name){
                    orderDetails.foodList[key] = {
                        foodName: param.name,
                        foodPrice: document.getElementsByClassName('price')[0].innerText,
                        foodQty: document.getElementById('food_qty').value,
                    }
                }else{
                    orderDetails.foodList.push({
                        foodName: param.name,
                        foodPrice: document.getElementsByClassName('price')[0].innerText,
                        foodQty: document.getElementById('food_qty').value,
                    });
                }
            })
        } else {
            orderDetails = {
                user: {
                    // userName: 'taknaja',
                    // userId: 'taknaja1234'
                    userName: resLiffProfile.displayName,
                    userId: resLiffProfile.userId,
                },
                foodList: [{
                    foodName: param.name,
                    foodPrice: document.getElementsByClassName('price')[0].innerText,
                    foodQty: document.getElementById('food_qty').value,
                }]
            }
        }
        localStorage.setItem('order_details', JSON.stringify(orderDetails));
        navigate("/foodmenu");
    }
    return (
        <div className="card mt-4 mb-4 shadow-sm card-top-category">
            <div id="page_create_order">
                <div className='flex justify-between border-b pb-3 block-top'>
                    <h1 className='foodmenu-title'>{param.name}</h1>
                    <Link to="/foodmenu"><FontAwesomeIcon icon={fas.faCircleXmark} size="2xl" className='btn-close-page' /></Link>
                </div>
                <div className='flex justify-between item-center border-t block-bottom'>
                    <div className='flex justify-start w-1/2 pe-3'>
                        <button type='button' id="btn-minus" className='btn-plus-minus' onClick={minus}>-</button>
                        <input type='text' className='text-center' id="food_qty" readOnly value={1} />
                        <button type='button' id="btn-plus" className='btn-plus-minus' onClick={plus}>+</button>
                    </div>
                    <button type='button' className='btn-add-cast w-1/2' onClick={orderAdd}>
                        <span>ใส่ตระกร้า</span>
                        <span className='price'>{param.price}</span>
                        <span className='ms-2'>บาท</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default PageCraeteOrder