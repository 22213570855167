import React from "react";
import axios from 'axios';
import DummyImage from '../images/dummy-food-image.png';
import TabCartStatus from "./TabCartStatus";
import { Link } from "react-router-dom";
const FoodMenu = () => {
    let chkCatArr = [];
    const [resCategory, getCategory] = React.useState([]);
    const [resFood, getFood] = React.useState([]);
    React.useEffect(() => {
        axios.get('/customer/product_category').then((res) => {
            getCategory(res.data);
        });
        axios.get('/customer/product').then((res) => {
            getFood(res.data);
        });
    }, []);

    const listCatItems = resCategory.map((item, key) =>
        <button type="button" className="btn-list-category me-2 mb-2 mt-2 flex-initial shrink-0 rounded-lg" key={key}>{item.name}</button>)
    const blockFoods = resCategory.map((item, key) => {

        return <div className="block" key={key}>
            <h1>{item.name}</h1>
            {
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
                    {
                        resFood.map((item_food, index) => {
                            if (item_food.category_name === item.name) {
                                return <Link to={`/order/${item_food.product_name}/${item_food.price}`}
                                    className="flex flex-initial align-item-center block-food-menulist rounded-lg shadow-sm cursor-pointer" key={index}
                                >
                                    <div className="block-food-img p-2">
                                        <img src={DummyImage} />
                                    </div>
                                    <div className="block-detail-food p-2">
                                        <h2 className="foodname-title">{item_food.product_name}</h2>
                                        <h3 className="foodname-price mt-2">{item_food.price} บาท</h3>
                                        <div className="flex justify-content-end align-items-center">

                                        </div>
                                    </div>
                                </Link>
                            }
                        })
                    }
                </div>
            }
        </div>
    }

    );
    return (
        <div className="card mt-4 mb-4 shadow-sm card-top-category w-full">
            <h1>เมนูอาหาร</h1>
            <div id="block-category-list" className="overflow-x-scroll flex flex-nowrap aligin-item-center">
                {listCatItems}
            </div>
            {blockFoods}
            <TabCartStatus />
        </div>
    )
}
export default FoodMenu;