
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import '../scss/page_cart.scss';


const PageCart = () => {
    let orderDetails = JSON.parse(localStorage.getItem('order_details'));
    let foodList = [];
    let total = 0;
    if (orderDetails != null) {
        foodList = orderDetails.foodList;
        foodList.forEach(item => {
            total = total + parseFloat(item.foodPrice);
        });
    }

    let navigate = useNavigate();
    const createOrder = () => {
        const formData = new FormData();
        formData.append('line_display_name', orderDetails.user.userName);
        formData.append('line_user_id', orderDetails.user.userId);
        formData.append('order_detail', JSON.stringify(foodList));
        formData.append('order_price_total', total)
        axios.post('/customer/order/create', formData).then((res) => {
            // const navigate = useNavigate();
            if (res.status == 200) {
                localStorage.removeItem('order_details');
                navigate('/foodmenu');
            }
        })
    }
    if (foodList.length > 0) {
        return (
            <div className="card mt-4 mb-4 shadow-sm card-top-category">
                <div className='flex justify-between mt-4 mb-4'>
                    <h1 className="text-md">รายการสั่ง</h1>
                    <Link to="/foodmenu" className="text-lg font-bold" id="bt-agan-order">
                        <FontAwesomeIcon icon={fas.faPlus} size='lg' />
                        <span className="ms-4">สั่งอาหารเพิ่ม</span>
                    </Link>
                </div>
                <div className="block w-full mt-9" id="block_cart_order_list">
                    {
                        foodList.map((item, key) => {
                            return (
                                <div key={key} className="flex w-full mt-4 mb-4 font-bold">
                                    <div className="w-6/12 text-lg">
                                        <span className="food-qty">{item.foodQty}</span>
                                        <span className="ms-2">{item.foodName}</span>
                                    </div>
                                    <div className="w-6/12 flex justify-end text-lg">
                                        <span>{item.foodPrice}</span>
                                        <span className="ms-2 me-2">บาท</span>
                                    </div>
                                    <div className="block ms-4">
                                        <button type="button" className='text-red-500'><FontAwesomeIcon icon={fas.faTrashCan} size='lg' /></button>
                                    </div>
                                </div>)
                        })
                    }
                    <div className="flex w-full mt-4 mb-4 font-bold justify-between text-2xl text-red-500">
                        <div>
                            <span>รวม</span>
                        </div>
                        <div>
                            <span>{total}</span>
                            <span className="ms-2">บาท</span>
                        </div>
                    </div>
                    <button className="flex w-full mt-4 mb-4 font-bold justify-between bg-red-500 text-white p-4 text-xl rounded-md" onClick={createOrder}>
                        <div>ยืนยันสั่งอาหาร</div>
                        <div>{total} <span className="ms-1">บาท</span></div>
                    </button>
                </div>
            </div>
        )
    } else {
        return (<div></div>)
    }

}
export default PageCart;