import Topbar from '../Layouts/Topbar';
import { RouterProvider } from 'react-router-dom';
import router from '../routes/Router';
const LiffLayouts = () => {
    return(
       <div>
         <Topbar/>
         <RouterProvider router={router} />
       </div>
    )
}
export default LiffLayouts